import { debounce } from 'lodash';
import { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';

import {
  ISearchResultsWixCode,
  IWidgetControllerConfig,
} from '../../../../lib/platform.types';
import { createSearchPlatformBiLogger } from '../bi';
import { convertResponseToSuggestions } from './convertResponseToSuggestions';
import {
  I$WEvent,
  I$WResult,
  ISearchBoxWixCode,
} from './searchAppControllerFactory.types';
import { Spec } from '../../../../lib/specs';
import { reportError } from '../../../../lib/errors';

export async function searchAppControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const {
    getCategoryList,
    platformAPIs,
    searchLocation,
    searchSDK,
    wixCodeApi,
    flowAPI,
  } = controllerConfig;

  const { $w } = controllerConfig;
  const { formFactor, viewMode } = wixCodeApi.window;
  const isMobile = formFactor === 'Mobile';
  const isSiteViewMode = viewMode === 'Site';
  const isDemoContent = !isSiteViewMode;
  const { experiments, errorMonitor } = flowAPI;

  const useModalSuggestions =
    isMobile && experiments.enabled(Spec.MobileSuggestions);

  function closeSuggestions(searchBox: ISearchBoxWixCode) {
    searchBox.suggestions = null;
    if (useModalSuggestions) {
      searchBox.closeSuggestions();
      searchBox.value = '';
    }
  }

  const bi = createSearchPlatformBiLogger(platformAPIs, errorMonitor);

  function getRelativeUrl(url: string): string {
    const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');
    return relativeUrl === '' ? '/' : relativeUrl;
  }

  return {
    async pageReady() {
      const searchBoxes: I$WResult<ISearchBoxWixCode> = $w('@searchBox');
      const searchResults: I$WResult<ISearchResultsWixCode> = $w(
        '@searchResults',
      );

      const hasSearchBoxOnPage = searchBoxes.length > 0;

      // There is a bug in the search editor script code and not all the time search controller has access to @searchResult role even when on search-results page
      const hasSearchResultsOnPage = searchResults.length > 0;

      if (!hasSearchBoxOnPage) {
        return;
      }

      const previousSearchBoxValueById: Record<
        string,
        string | undefined
      > = searchBoxes.reduce(
        (result, { uniqueId, value }) => ({
          ...result,
          [uniqueId]: value,
        }),
        {},
      );

      searchBoxes.onClear((e) => {
        try {
          bi.resetRequestCorrelationId();
          if (!e) {
            return;
          }
          const previousSearchValue =
            previousSearchBoxValueById[e.target.uniqueId] ?? '';

          // Multiple bugs in SearchBox Velo SDK clear functionality.
          // Remove this when fixed: https://jira.wixpress.com/browse/SER-2067
          previousSearchBoxValueById[e.target.uniqueId] = e.target.value;

          bi.searchBoxCleared({
            isDemo: isDemoContent,
            searchQuery: previousSearchValue,
          });
        } catch (error) {
          reportError(errorMonitor, error);
        }
      });

      searchBoxes.onSubmit((e) => {
        try {
          const searchBox = e.target;
          const searchQuery = searchBox.value;

          bi.searchSubmit({
            isDemoContent,
            searchQuery,
          });

          // NOTE: Ugly workaround for some websites where controller is created and attached
          // to the same SearchBox component multiple times.
          setTimeout(() => {
            searchBox.value = '';
          }, 0);

          closeSuggestions(searchBox);

          if (!hasSearchResultsOnPage) {
            // NOTE: navigation with not empty search query works correctly only in `Site` view mode
            // TODO: we could navigate with searchQuery in preview mode when `sv_editorNativeComponents` opened
            searchLocation.navigateToSearchResults({
              query: isSiteViewMode ? searchQuery : '',
            });
          } else {
            // NOTE: editor version of searchResults component, doesn't return valid controller instance
            // so `changeQuery` call does not affect search results component
            // should be fixed when `sv_editorNativeComponents` opened
            // https://github.com/wix-private/site-search/issues/130
            searchResults.changeQuery(searchQuery);
          }
        } catch (error) {
          reportError(errorMonitor, error);
        }
      });

      searchBoxes.onFocus((e) => {
        try {
          bi.searchBoxFocused({
            isDemo: isDemoContent,
          });
          if (
            !isDemoContent &&
            useModalSuggestions &&
            e.target.suggestionsEnabled
          ) {
            e.target.openSuggestions();
          }
        } catch (error) {
          reportError(errorMonitor, error);
        }
      });

      searchBoxes.onChange(({ target }) => {
        try {
          const { value, uniqueId } = target;
          const previousValue = previousSearchBoxValueById[uniqueId] ?? '';

          if (!previousValue.length && value.length > 0) {
            bi.searchBoxStartedWritingAQuery({
              isDemo: isDemoContent,
            });
          }

          previousSearchBoxValueById[uniqueId] = value;
        } catch (error) {
          reportError(errorMonitor, error);
        }
      });

      if (!isDemoContent && (!isMobile || useModalSuggestions)) {
        const baseResultsPageUrl = await searchLocation.getSearchResultsAbsoluteUrl();

        const loadSuggestions = (minimumSearchTermLength: number) => async (
          e: I$WEvent<ISearchBoxWixCode>,
        ) => {
          const searchBox = e.target;
          const searchQuery = searchBox.value;

          if (!searchBox.suggestionsEnabled) {
            return;
          }

          if (!searchQuery) {
            bi.resetRequestCorrelationId();
          }

          if (useModalSuggestions && searchQuery.trim() === '') {
            searchBox.suggestions = null;
            return;
          }

          if (
            searchQuery.length < minimumSearchTermLength &&
            !useModalSuggestions
          ) {
            closeSuggestions(searchBox);
            return;
          }

          const biSearchBoxSuggestionsRequestFinished = bi.searchBoxSuggestionsRequestStarted(
            { searchQuery },
          );

          try {
            const federatedResponse = await searchSDK.getFederatedSuggestions({
              query: searchQuery,
              limit: 4,
            });
            const categoryList = await getCategoryList();

            const footerUrl = searchLocation.buildSearchResultsUrl(
              baseResultsPageUrl,
              {
                query: searchQuery,
              },
            );

            const federatedSuggestions = convertResponseToSuggestions({
              baseResultsPageUrl,
              categoryList,
              federatedResponse,
              footerUrl,
              searchLocation,
              searchQuery,
            });

            if (searchQuery === searchBox.value) {
              searchBox.suggestions = federatedSuggestions;
            }

            biSearchBoxSuggestionsRequestFinished({
              success: true,
              suggestions: federatedSuggestions.items,
            });
          } catch (error) {
            biSearchBoxSuggestionsRequestFinished({
              success: false,
              error: String(error),
              suggestions: [],
            });

            reportError(errorMonitor, error);
          }
        };

        const isShorterSearchTermEnabled = experiments.enabled(
          Spec.ShorterSearchTerm,
        );

        searchBoxes.onChange(
          debounce(
            loadSuggestions(isShorterSearchTermEnabled ? 1 : 3),
            isShorterSearchTermEnabled ? 100 : 300,
          ),
        );

        searchBoxes.onSuggestionSelect((e) => {
          try {
            const searchBox = e.target;
            const { title, data } = e.syntheticEvent;
            const { url, query, globalIndex, groupId, type } = data;

            if (type === 'item') {
              bi.searchBoxSuggestionClick({
                title,
                url,
                searchQuery: query,
                index: globalIndex,
                documentType: groupId,
                suggestions: searchBox.suggestions?.items || [],
              });
            }
            // Compatibility with Bolt component (clicking on a suggestions group uses onSuggestionSelect()
            // in Bolt and onSuggestionGroupSelect() in Thunderbolt).
            else {
              bi.searchBoxSuggestionShowAllClick({
                searchQuery: query,
                documentType: groupId,
              });
            }

            closeSuggestions(searchBox);

            searchLocation.navigateTo(getRelativeUrl(url));
          } catch (error) {
            reportError(errorMonitor, error);
          }
        });

        searchBoxes.onSuggestionsFooterClick(async (e) => {
          try {
            const query = e.target.value;
            bi.searchBoxSuggestionSearchAllClick({
              searchQuery: query,
            });
            closeSuggestions(searchBoxes);
            await searchLocation.navigateToSearchResults({ query });
          } catch (error) {
            reportError(errorMonitor, error);
          }
        });

        searchBoxes.onSuggestionGroupSelect((e) => {
          try {
            /* SearchBox::Bolt does not use `onSuggestionGroupSelect`:
             * https://github.com/wix-private/wix-ui-santa/search?q=onSuggestionGroupSelect
             * https://github.com/wix-private/site-search/search?q=handleSuggestionGroupSelect
             *
             * However old implementation of API leaked to the users at least once.
             *
             * In Bolt implementation of SearchBox, fields (url, query, groupId) were defined
             * directly on `syntheticEvent` (without `data` layer).
             *
             * In SearchBox::TB we moved to unified solution and introduced `syntheticEvent.data`.
             *
             * This guard should help to avoid unexpected failures in the user code.
             */
            if (!e.syntheticEvent?.data) {
              return;
            }

            const { url, query, groupId } = e.syntheticEvent.data;
            closeSuggestions(searchBoxes);

            bi.searchBoxSuggestionShowAllClick({
              searchQuery: query,
              documentType: groupId,
            });

            const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');
            searchLocation.navigateTo(relativeUrl);
          } catch (error) {
            reportError(errorMonitor, error);
          }
        });
      }
    },
  };
}
