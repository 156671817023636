import {
  ClientSearchSDK,
  IErrorResponse,
  ISearchRequest,
  ISearchResponse,
  ISearchResponseTotals,
  SearchDocumentType,
} from '@wix/client-search-sdk';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { ISearchLocation } from '../../../../../lib/location';
import { ISearchRequestBiLogger } from '../bi';
import {
  ISearchSample,
  VisibleCategories,
} from '../searchResultsControllerStore';
import { getSearchResponse } from './getSearchResponse';
import { getSamplesResponse } from './getSamplesResponse';
import { sortCategories } from './sortCategories';
import { getDocumentIds } from './getDocumentIds';
import { SearchRequestStatus } from '../../types/types';
import { getPriceFacetResponse } from './getPriceFacetResponse';
import { getCollectionsFacetResponse } from './getCollectionsFacetResponse';
import { mergeFacets } from '../products';

export type SearchParams = {
  bi: ISearchRequestBiLogger;
  environment: ControllerFlowAPI['environment'];
  facetsEnabled: boolean;
  previousQuery: string | undefined;
  previousTotals: ISearchResponseTotals | undefined;
  previousSearchRequestStatus: SearchRequestStatus;
  previousSearchRequest: ISearchRequest;
  previousSearchResponse: ISearchResponse;
  searchLocation: ISearchLocation;
  searchRequest: ISearchRequest;
  searchResultsAbsoluteUrl: string;
  searchSDK: ClientSearchSDK;
  visibleCategories: VisibleCategories;
};

export const search = async (
  searchParams: SearchParams,
): Promise<
  | {
      searchResponse: ISearchResponse;
      searchResponseTotals: ISearchResponseTotals;
      searchSamples: ISearchSample[];
    }
  | IErrorResponse
> => {
  const {
    bi,
    environment,
    facetsEnabled,
    previousQuery,
    previousTotals,
    searchLocation,
    searchRequest,
    searchResultsAbsoluteUrl,
    searchSDK,
    visibleCategories,
  } = searchParams;

  try {
    bi.started();

    const shouldShowSamples =
      searchRequest.documentType === SearchDocumentType.All;

    const sortedCategories = sortCategories(visibleCategories);

    const [
      searchResponse,
      [searchResponseTotals, searchSampleResponse],
      priceFacetsResponse,
      collectionsFacetsResponse,
    ] = await Promise.all([
      getSearchResponse(searchRequest, shouldShowSamples, searchSDK),
      getSamplesResponse(
        searchRequest,
        shouldShowSamples,
        previousQuery !== searchRequest.query,
        searchSDK,
        environment,
        previousTotals || {},
      ),
      facetsEnabled
        ? getPriceFacetResponse(searchParams)
        : Promise.resolve(undefined),
      facetsEnabled
        ? getCollectionsFacetResponse(searchParams)
        : Promise.resolve(undefined),
    ]);

    if ('isError' in searchResponse) {
      return searchResponse;
    }

    if ('isError' in searchResponseTotals) {
      return searchResponseTotals;
    }

    if ('isError' in searchSampleResponse) {
      return searchSampleResponse;
    }

    if (priceFacetsResponse && 'isError' in priceFacetsResponse) {
      return priceFacetsResponse;
    }

    if (collectionsFacetsResponse && 'isError' in collectionsFacetsResponse) {
      return collectionsFacetsResponse;
    }

    if (facetsEnabled) {
      searchResponse.facets = mergeFacets(
        priceFacetsResponse || searchResponse,
        collectionsFacetsResponse || searchResponse,
      );
    }

    if (shouldShowSamples) {
      searchResponse.totalResults =
        searchResponseTotals[SearchDocumentType.All] || 0;
    }

    const searchSamples: ISearchSample[] = searchSampleResponse.results
      .map((s) => ({
        ...s,
        url: searchLocation.buildSearchResultsUrl(searchResultsAbsoluteUrl, {
          query: searchRequest.query,
          documentType: s.documentType,
        }),
      }))
      .sort(
        (s1, s2) =>
          sortedCategories.indexOf(s1.documentType) -
          sortedCategories.indexOf(s2.documentType),
      );

    bi.finished(
      searchResponse,
      searchResponseTotals,
      getDocumentIds({
        searchResponse,
        searchSamples,
        shouldShowSamples,
      }),
    );

    return {
      searchResponse,
      searchResponseTotals,
      searchSamples,
    };
  } catch (error) {
    bi.failed(error);
    throw error;
  }
};
