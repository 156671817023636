import { ErrorMonitor } from '@wix/yoshi-flow-editor/error-monitor';
import {
  IPlatformAPI,
  IWebStorage,
} from '@wix/native-components-infra/dist/src/types/types';
import { aMemoryStorage } from '@wix/native-components-infra/dist/src/memoryStorage/memoryStorage';

import { reportError } from '../errors';
import { guid } from '../guid';

export enum BiCorrelationIdKey {
  SearchSuggestions = 'BICorrelationId:SearchSuggestions',
  SearchSubmit = 'BICorrelationId:SearchSubmit',
}

export function createBiCorrelationId(): string {
  return guid();
}

export class BiCorrelationIdStore {
  private readonly storage?: IWebStorage;

  constructor(
    platformApi: IPlatformAPI,
    private readonly errorMonitor: ErrorMonitor,
  ) {
    // NOTE: storage could be not available in some browsers
    // https://sentry.io/organizations/wix_o/issues/1140629343/
    // https://sentry.io/organizations/wix_o/issues/1145676561/
    this.storage = [
      platformApi.storage.session,
      platformApi.storage.memory,
      aMemoryStorage,
    ].find((_storage) => _storage && typeof _storage.getItem === 'function');
  }

  set(key: BiCorrelationIdKey, correlationId: string): void {
    this.storage?.setItem(key, correlationId);
  }

  get(key: BiCorrelationIdKey): string {
    const correlationId = this.storage?.getItem(key);
    if (!correlationId) {
      reportError(
        this.errorMonitor,
        new Error(`storage key '${key}' is not available`),
      );
      return '';
    }
    return correlationId;
  }

  has(key: BiCorrelationIdKey): boolean {
    return this.storage ? this.storage.getItem(key) !== null : false;
  }
}
